<template>
<!-- <div class="row"> -->

    <div class="col-12" v-if="fromPage == 'checkout'">
        <div class="mt-2 mb-3" >
            <a href="#" v-on:click.prevent="newCard = !newCard">
                <span><fa icon="plus" /></span> Add a new card
            </a>
        </div>
    </div>
    <div class="col-12 mb-5" v-show="newCard">
        <div class="row">
            <div class="col-12"><h6 class="mb-3 mt-2">Credit/ Debit Card <img src="@/assets/images/cards/credit-cards-logos.png" class="img-fluid ms-sm-3"></h6></div>
            <div class="col-12">
                <StripeElement :element="cardElement" @change="cardEvent = $event" />
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6">
                <div class="form-group">
                    <input type="text" v-model="card_.name" class="form-control" placeholder="Enter your Full Name">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <input type="text" v-model="card_.address" class="form-control" placeholder="Enter Full Address">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button v-on:click="newCard=false" class="ond-btn-w float-start">Cancel</button>
                <button v-on:click="addCard" class="ond-btn-p float-end">Add
                    <span v-show="addCardSpinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </div>
    <!-- <div class="col-sm-6" v-for="card in payment_cards" :key="card.id">
        <label class="form-check-label w-100" :for="'cardRadio'+card.id">
            <div class="card-selection" >
                <div class="col-1 col-md-2">
                    <label style="margin-bottom: 0px;">
                        <input type="radio" name="card" v-model="selectedCard" v-bind:value="card.id" class="form-check-input" :id="'cardRadio'+card.id" />
                    </label>
                </div>
                <div class="col-4 col-md-3">
                    <img src="@/assets/images/cards/Visa.png">
                </div>
                <div class="col-7">
                    <p class="card-number"><span>*** *** ****</span>{{card.last_four}}</p>
                    <p class="card-type">{{card.brand}}</p>
                </div>
            </div>
        </label>
    </div> -->


    <div class="col-12"  v-if="fromPage == 'checkout'">
        <div class="card border saved_payments">
            <div class="card-header fw-bold">Saved payment options</div>
            <div class="card-body px-2 px-sm-3">
                <ul class="list-unstyled">
                    <li class="mb-3" v-for="card in payment_cards" :key="card.id">
                        <label class="form-check-label w-100" :for="'cardRadio'+card.id">
                            <input type="radio" name="card" class="form-check-input me-2 me-sm-3 mt-2" v-model="selectedCard" v-bind:value="card.id" :id="'cardRadio'+card.id">
                            <img v-if="card.brand == 'Visa'" src="@/assets/images/cards/card5.jpg" class="align-middle me-1 me-sm-3">
                            <img v-else-if="card.brand == 'MasterCard'" src="@/assets/images/cards/card2.jpg" class="align-middle me-1 me-sm-3">
                            <img v-else src="@/assets/images/cards/default.jpg" class="align-middle me-1 me-sm-3">
                            {{card.brand}} Ending in *** *** **** {{card.last_four}}
                        </label>
                    </li>
                </ul>
                <div v-if="payment_cards.length == 0" class="col-12"><small>No card found</small></div>
            </div>
        </div>
    </div>






 <!-- Work Start -->
    <div class="col-12 boxed-card"  v-if="fromPage == 'subscription'">
        <div class="row">
            <div class="col-8"><h5 class="my-2">Saved payment options</h5></div>
            <div class="col-4">
                <div class="m-0 float-end me-1">
                    <a href="#" v-on:click.prevent="newCard = !newCard" class="plusIcon me-2">
                        <fa icon="plus" />
                    </a>
                </div>
            </div>
            <div class="col-12 saved_payments">
                <ul class="list-unstyled">
                    <li class="" v-for="card in payment_cards" :key="card.id">
                        <label class="form-check-label w-100 align-middle" :for="'cardRadio'+card.id">
                            <input type="radio" name="card" class="form-check-input me-2 me-sm-3 mt-2" v-model="selectedCard" v-bind:value="card.id" :id="'cardRadio'+card.id">
                            <img v-if="card.brand == 'Visa'" src="@/assets/images/cards/card5.jpg" class="me-1 me-sm-3">
                            <img v-else-if="card.brand == 'MasterCard'" src="@/assets/images/cards/card2.jpg" class="me-1 me-sm-3">
                            <img v-else src="@/assets/images/cards/default.jpg" class="me-1 me-sm-3">
                            <span>{{card.brand}} Ending in *** *** **** {{card.last_four}}</span>
                        </label>
                    </li>
                </ul>
                <div v-if="payment_cards.length == 0" class="col-12"><small>No card found</small></div>
            </div>
        </div>
    </div>

    <!-- for subscription_plans -->

     <div class="col-12 boxed-card-subPlans"  v-if="fromPage == 'subscriptionPlans'">
        <div class="row" v-show="cardDetail = !cardDetail"  >
            <div class="col-8">
                <h5 class="my-2">Saved payment options</h5>
            </div>
            <div class="col-4">
                <div class="m-0 float-end me-1">
                    <a href="#" class="plusIcon me-2" v-on:click.prevent="newCard = !newCard" >
                        <fa icon="plus" />
                    </a>
                </div>
            </div>
            <div class="col-12 saved_payments" >
                <ul class="list-unstyled">
                    <li class="" v-for="card in payment_cards" :key="card.id">
                        <label class="form-check-label w-100 align-middle" :for="'cardRadio'+card.id">
                            <input type="radio" name="card" class="form-check-input me-2 me-sm-3 mt-2" v-model="selectedCard" v-bind:value="card.id" :id="'cardRadio'+card.id">
                            <img v-if="card.brand == 'Visa'" src="@/assets/images/cards/card5.jpg" class="me-1 me-sm-3">
                            <img v-else-if="card.brand == 'MasterCard'" src="@/assets/images/cards/card2.jpg" class="me-1 me-sm-3">
                            <img v-else src="@/assets/images/cards/default.jpg" class="me-1 me-sm-3">
                            <span>{{card.brand}} Ending in *** *** **** {{card.last_four}}</span>
                        </label>
                    </li>
                </ul>
                <div v-if="payment_cards.length == 0" class="col-12"><small>No card found</small></div>
            </div>
        </div>
    </div>
    <!-- end subscription_plans -->


    <!-- Work End -->


    <!-- <div class="col-12">
        <div class="card border saved_cards">
            <div class="card-header">Saved payment options</div>
            <div class="card-body p-2">
                <ul class="list-unstyled mb-0">
                    <li class="py-2">
                        <label for="radio1"><input type="radio" name="saved_card" id="radio1" class="form-check-input me-2" checked><img src="@/assets/images/cards/card5.jpg" class="me-2">Visa Ending in **** 4718 | Expires 05 / 22</label>
                    </li>
                    <li class="py-2">
                        <label for="radio2"><input type="radio" name="saved_card" id="radio2" class="form-check-input me-2"><img src="@/assets/images/cards/card1.jpg" class="me-2">Express Ending in **** 4718 | Expires 05 / 22</label>
                    </li>
                    <li class="py-2">
                        <label for="radio3"><input type="radio" name="saved_card" id="radio3" class="form-check-input me-2"><img src="@/assets/images/cards/card2.jpg" class="me-2">MasterCard Ending in **** 4718 | Expires 05 / 22</label>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
<!-- </div> -->
</template>

<script>
import { useStripe, StripeElement } from 'vue-use-stripe'
import { ref } from 'vue'
import axios from 'axios'
import { useToast } from "vue-toastification";
export default {
    components: { StripeElement },
    props:{
        cardData:Function,
        fromPage:{
            type: String,
            default:'checkout'
        }
    },
    data() {
        return {
            card_:{
                name:'',
                address:"",
                token:''
            },
            addCardSpinner : false,
            selectedCard:'',
            payment_cards:[],
            newCard:false,
            cardDetail:false
        }
    },
    created(){
        this.paymentCards()
    },
    methods:{
        // checkCardError(e){
        //     console.log(e)
        //     if (e && e.error) {
        //         this.cardError = e.error.message
        //     }
        // },
        addCard(){
            let thiss = this
            if (this.cardEvent) {
                if (this.cardEvent.complete) {
                    thiss.addCardSpinner = true;
                    this.stripe.createToken(this.cardElement).then(function (result) {
                        if (result.error) {
                            thiss.toast.error(result.error.message)
                        } else {
                            // Send the token to your server.
                            thiss.card_.token = result.token
                            console.log(thiss.card_)
                            axios.post('add_payment_method',thiss.card_).then(function (response) {
                                thiss.addCardSpinner = false;
                                if (response.data.status == 'success') {
                                    thiss.paymentCards()
                                    thiss.newCard = false
                                }
                            })
                            .catch(error => {
                                thiss.addCardSpinner = false;
                                if (error.response) {
                                    thiss.toast.error(error.response.data.message);
                                }
                            });
                        }
                    });
                }
            }
        },
        paymentCards(){
            let thiss = this
            axios.get('payment_cards').then(function (response) {
                //console.log(response.data)
                if (response.data.status == 'success') {
                    thiss.payment_cards = response.data.data
                    if (thiss.selectedCard == '') {
                        response.data.data.forEach(element => {
                            if (element.is_default == 1) {
                                thiss.selectedCard = element.source_id
                            }
                        });
                    }
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
    },
    setup() {
        const toast = useToast();
        const cardEvent = ref(null)
        const style = {
            base: {
                'font-family': 'Roboto',
                'color': '#605e7e',
                '::placeholder': {
                    color: '#a7b4c9',
                    opacity: 1
                }
            }
        };
        const {stripe,elements: [cardElement]} = useStripe({
            key : process.env.VUE_APP_STRIPE_KEY,
            elements: [{ type: 'card', options: {style: style} }],
        })
        return {
            cardElement,
            cardEvent,
            stripe,
            toast
        }
    },
    watch: {
        selectedCard: function(data) {
            this.cardData(data)
        }
    }
}
</script>

<style scoped>
    .card-header {
        padding: 0.5rem 1rem;
        margin-bottom: 0;
        background-color: rgba(0,0,0,.03);
        border-bottom: 1px solid rgba(0,0,0,.125);
    }
    .form-check-input{
        position: unset;
        margin-left: 0;
    }
    .card-body{
        max-height: 200px;
        overflow-y: auto;
        margin: 0;
        padding-top: 10px;
    }
    .StripeElement {
        display: block;
        width: 100%;
        padding: 11px 12px;
        font-size: 0.9375rem;
        line-height: 1.6;
        color: #605e7e;
        height: 39px;
        background-color: #fff;
        background-clip: padding-box;
        box-shadow: none;
        border: 1px solid #e8ebf3;
        border-radius: 3px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .card-selection{
        background-color: #f3f3f3;
        background-image: linear-gradient(#fefefe, #f0f0f0);
        border: 2px solid #eee;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        max-width: 350px;
        margin: 0 15px 15px 0;
        display: -webkit-box;
        float: left
    }
    .card-selection.active{
        border: 2px solid #666 !important
    }
    .card-selection input{
        margin-top: 13px
    }
    .card-selection img{
        margin-top: 5px;
        max-width: 50px
    }
    .card-number{
        color: #828483;
        width: 100%;
        text-align: right;
        font-size: 14px;
        font-weight: bold
    }
    .card-type{
        color: #a2a2a2;
        width: 100%;
        text-align: right;
        font-size: 13px;
        font-weight: 500;
        margin: 0
    }
    .boxed-card .plusIcon{
        background: #6e317a;
        font-size: 15px;
        color: white;
        border-radius: 100%;
        padding: 8px 10px;
    }
    .boxed-card li{
        padding: 7px 10px;
    }

    .card-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px rgb(235, 235, 235);
        border-radius: 10px;
        background-color: #fff;
    }
    .card-body::-webkit-scrollbar {
        width: 6px;
        background-color: #fff;
    }
    .card-body::-webkit-scrollbar-thumb {
        background-color: rgb(197, 197, 197);
        border-radius: 10px;
    }
    /* .boxed-card ul{
        max-height: 100px;
        overflow-y: auto;
    } */
</style>