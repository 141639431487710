<template>
    <div v-if="store.businessDetail">
        <div class="work-images" v-if="store.businessDetail.photos.length > 0">
            <h3 class="mb-4">
                Photos <span>({{store.businessDetail.photos.length}})</span>
                <a href="" class="ms-4" @click.prevent="viewAllPhotos">See all</a>
            </h3>
            <div class="text-center" v-if="$route.params.tab != 'gallery'">
                <div class="justify-content-center gallery-slider">
                    <Carousel :settings="{snapAlign: 'start',itemsToShow:1,wrapAround:false}" :breakpoints="breakpoints" v-if="store.businessDetail.photos.length > 0">
                        <Slide v-for="(photo,index) in store.businessDetail.photos" :key="index">
                            <a
                                :data-info="photo.description"
                                :href="photo.image"
                                :data-thumb ="photo.image"
                                :data-download-src="photo.image"
                                data-fancybox="gallery"
                                :data-caption="photo.title"
                                :data-id="photo.id"
                                :data-date="photo.created_at"
                                :style="{ backgroundImage: 'url('+photo.image+')' }"
                                :alt="photo.title"
                            ></a>
                        </Slide>

                        <template #addons>
                            <Navigation />
                        </template>
                    </Carousel>
                </div>
            </div>
            <div class="row" v-if="$route.params.tab == 'gallery'">
                <div class="col-12 col-sm-6 col-md-4 mb-3" v-for="photo in store.businessDetail.photos" :key="photo.id">
                    <a :href="photo.image" data-fancybox="gallery" :data-caption="photo.caption" >
                        <img :src="photo.image" class="img-thumbnail h-100" :alt="photo.caption">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from "vue";
    import { Carousel, Navigation, Slide } from "vue3-carousel";
    import moment from 'moment'
    import { Fancybox } from "@fancyapps/ui";
    import "@fancyapps/ui/dist/fancybox.css";
    import { useBusinessStore } from "@/stores/business";
    const store = useBusinessStore();

    const breakpoints = ref({
      // 700px and up
      768: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
      415: {
        itemsToShow: 2,
        snapAlign: 'start',
      }
    })

    const getSettings = (type = "") => {
        return {
            click: () => {
                return false;
            },
            caption: () => {
                return null
            },
            Toolbar: {
                display: [
                    {id: "counter",position: "center"},
                    { id: "download", position: "left" },
                    "zoom",
                    "slideshow",
                    "fullscreen",
                    "thumbs",
                    "close",
                ],
            },
            on: {
                initLayout: (fancybox) => {
                    const { business_info: { title } } = store.businessDetail;
                    const { items } = fancybox;

                    const $leftCol = document.createElement("div");
                    $leftCol.classList.add("fancybox__leftCol");

                    while (fancybox.$container.firstChild) {
                        $leftCol.appendChild(fancybox.$container.firstChild);
                    }

                    const $rightCol = document.createElement("div");
                    $rightCol.classList.add("fancybox__rightCol");

                    const item = items[0];

                    $rightCol.innerHTML = `
                        <div class="mb-2">
                            <h4 class="fancy_title">${title}</h4>
                            <p class="itemLength"><span id="fancy_counter">1</span> of ${items.length}</p>
                        </div>
                        <div>
                            <div id="fancybox-caption"></div>
                            <div id="fancybox-info" class="content-font-size"></div>
                            <div id="fancybox-date" class="content-font-size"></div>
                            <div class="fancy_btns">
                                <button id="imgHelpful" class="btn help-button me-3 mb-2 shadow-sm" data-id="${item.id}">Helpful</button>
                                <button id="imgReport" class="btn help-button shadow-sm mb-2" data-id="${item.id}">Report</button>
                            </div>
                        </div>
                    `;

                    const $caption = $rightCol.querySelector("#fancybox-caption");
                    const $info = $rightCol.querySelector("#fancybox-info");
                    const $date = $rightCol.querySelector("#fancybox-date");

                    fancybox.$caption = $caption;
                    fancybox.$info = $info;
                    fancybox.$date = $date;

                    // Add elements to DOM
                    fancybox.$container.appendChild(fancybox.$backdrop);
                    fancybox.$container.appendChild($leftCol);
                    fancybox.$container.appendChild($rightCol);

                    fancybox.$leftCol = $leftCol;
                    fancybox.$rightCol = $rightCol;

                    // console.log(fancybox);
                },
                "Carousel.ready Carousel.change": (fancybox, carousel, slideIndex) => {
                    slideIndex = slideIndex ?? carousel.options.initialPage;
                    document.getElementById("fancy_counter").innerHTML = parseInt(slideIndex) + 1;

                    if (type === 'all') {
                        const item = fancybox.items[slideIndex];
                        fancybox.$caption.innerHTML = `<h6>${item.caption}</h6>`;
                        fancybox.$info.innerHTML = `<p>${item.info}</p>`;
                        fancybox.$date.innerHTML = `<p>On ${moment(item.date).format('MMMM DD, YYYY')}</p>`;

                        const helpful = document.getElementById('imgHelpful');
                        helpful.dataset.id = item.id;
                        const report = document.getElementById('imgReport');
                        report.dataset.id = item.id;
                    } else {
                        const $trigger = fancybox.items[slideIndex].$trigger;
                        const dataCap = $trigger.dataset.caption || '';
                        const dataInfo = $trigger.dataset.info || '';
                        const date = $trigger.dataset.date || '';
                        const id = $trigger.dataset.id || '';

                        fancybox.$caption.innerHTML = `<h6>${dataCap}</h6>`;
                        fancybox.$info.innerHTML = `<p>${dataInfo}</p>`;
                        fancybox.$date.innerHTML = `<p> ${moment(date).format('MMMM DD, YYYY')}</p>`;

                        const helpful = document.getElementById('imgHelpful');
                        helpful.dataset.id = id;
                        const report = document.getElementById('imgReport');
                        report.dataset.id = id;
                    }

                    const element = document.getElementById('imgHelpful');
                    element.onclick = () => helpfulProcess(element.dataset.id);

                    const element1 = document.getElementById('imgReport');
                    element1.onclick = () => reportProcess(element1.dataset.id);

                }
            }
        }
    }

    // create a new method to open all photos in fancybox gallery
    const viewAllPhotos = () => {
        const items = store.businessDetail.photos.map((photo) => ({
            src: photo.image,
            thumb: photo.image,
            caption: photo.caption,
            id: photo.id,
            info: photo.description,
            date: photo.created_at,
        }));
        Fancybox.show(items, getSettings('all'));
    };

    const helpfulProcess = (id) => {
        alert(id)
    }
    const reportProcess = (id) => {
        alert(id)
    }

    onMounted(() => {
        Fancybox.bind('[data-fancybox="gallery"]', getSettings());
    });
</script>

<style type="text/css">
    .gallery-slider .carousel__slide {
        padding: 0 0 1rem !important;
        height: 220px;
        position: relative;
    }
    .gallery-slider .carousel__slide a {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transform: translate(0, 0);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .fancybox__container {
        z-index: 1055;
    }

    a[data-fancybox] img {
        cursor: zoom-in;
    }

    .fancybox__container {
        /* --fancybox-bg: #fff; */
        --fancybox-color: #333;
        --fancybox-accent-color: #e879f9;

        --carousel-button-svg-filter: none;

        display: flex;
        flex-direction: row;
    }

    .fancybox__toolbar {
        position: relative;

        padding: 0;
        background: rgba(24, 24, 27, 0.8);
        color: #fff;
        /* backdrop-filter: blur(30px); */
        /* border-bottom: 1px solid #edeef0; */
    }

    .fancybox__slide {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .fancybox__nav {
        --carousel-button-color : #fff;
        /* --carousel-button-bg: #e5e7eb;
        --carousel-button-border-radius: 6px;

        --carousel-button-svg-width: 20px;
        --carousel-button-svg-height: 20px;

        --carousel-button-svg-stroke-width: 2.5; */
    }

    .fancybox__leftCol {
        width: 80vw;
        min-height: 1px;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
    }

    .fancybox__rightCol {
        width: 20vw;
        max-width: 300px;
        height: 100%;
        padding: 2rem 1rem;
        overflow-x: hidden;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #edeef0;
        opacity: var(--fancybox-opacity, 1);
    }

    /* Opening */
    .fancybox__container.is-animated[aria-hidden="false"] .fancybox__rightCol {
        animation: 0.15s ease backwards fancybox-fadeIn;
    }

    /* Closing */
    .fancybox__container.is-animated.is-closing .fancybox__rightCol {
        animation: 0.15s ease both fancybox-fadeOut;
    }

    .fancybox__container.is-animated.is-closing .fancybox__leftCol {
        overflow: visible;
    }
    .itemLength{
        border-bottom: 1px solid #ddd;
        padding-bottom: 14px;
    }.content-font-size{
        font-size: 14px;
    }
    .help-button{
        padding: 6px 16px;
    }
    @media (max-width: 640px){
        .fancybox__toolbar__items--center:not(:last-child) {
            display: block;
        }
        .fancybox__toolbar__items--center {
            left:40%
        }

    }

    @media screen and (max-width:576px) {
        .fancybox__leftCol{
            width: 100vw;
        }
        .fancybox__rightCol{
            width: 0px;
            padding: 0px;
        }
        .fancybox__toolbar__items--left{
            display: none;
        }
        .fancybox__counter {
            display: block;
        }
        .fancybox__toolbar__items--center {
            left:12%
        }
    }
</style>
