<template>
    <div class="row mb-2 d-block d-sm-none">
        <div class="col-12">
            <a href="javascript:void(0)" v-on:click.prevent="$router.push({name:'BusinessProfile'})" class="text-dark"><fa icon="angle-left" /> Back</a>
        </div>
    </div>
    <div class="overview-onbox">
        <div class="deals-tab">
            <div class="row" v-if="deals">
                <div class="col-md-6">
                    <h3>Deals</h3>
                    <!-- <div class="view-all">
                        Deals <a href="#">See all <fa icon="angle-right" /></a> 
                    </div>-->
                </div>
                <!-- <div class="col-lg-6 col-md-6">
                    <div class="continue-btn text-end">
                        <button class="btn default-button">Continue</button>
                    </div>
                </div> -->
            </div>
            <div class="row" v-if="deals">
                <div class="col-6 px-1 px-md-3" v-for="deal in deals" :key="deal.id">
                    <router-link :to="'/deal/' + deal.slug">
                        <div class="deals-block">
                            <div class="deals-list">
                                <div class="thumb">
                                    <img v-if="deal.banner" :src="deal.banner" alt="" class="img-fluid" />
                                    <img v-else src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/deals/default.jpg" alt="" class="img-fluid" />
                                    <!-- <div class="mi-no">25 mi</div> -->
                                    <div class="price-tag"><span class="regular-price">${{deal.total_price}}</span> ${{deal.discounted_price}}</div>
                                </div>
                                <div class="content">
                                    <h3 class="title">{{deal.title}}</h3>
                                    <p>High Lights Introducing 15% off color services, for a limited time. but not limited to customer...</p>
                                    <!-- <p>Provider: {{deal.user.name}}</p> -->
                                    <!-- <div class="booknow text-end">
                                        <a href="#" class="outling-button">Book Now <fa icon="long-arrow-alt-right" /></a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-10">
                    <div class="deals-block">
                        <div class="deals-list">
                            <div class="thumb">
                                <img src="@/assets/images/deals/deal-1.jpg" alt="" class="img-fluid" />
                                <div class="mi-no">25 mi</div>
                                <div class="price-tag"><span class="regular-price">$80</span> $21</div>
                            </div>
                            <div class="content">
                                <h3 class="title">What is Lorem Ipsum?</h3>
                                <p>2 Days Left</p>
                                <div class="booknow text-end">
                                    <a href="#" class="outling-button">Book Now <fa icon="long-arrow-alt-right" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row" v-else>
                <div class="col-12">
                    <div class="text-center p-md-5">
                        <img src="@/assets/images/deals/no-deals.svg" style="width: 150px">
                        <h3 class="fw-normal">No Deals Posted</h3>
                        <p>You may check back later to see deals</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {deals:Array}
    }
</script>

<style>
    @media (max-width:400px) {
        .deals-tab h3{
            font-size: 20px;
            margin-bottom: 0
        }
        .deals-list .price-tag{
            bottom: 5px;
            right: 5px;
            font-size: 10px;
            padding: 0 3px
        }
        .deals-list .content{
            padding: 8px 5px
        }
        .deals-list .title{
            font-size: 14px
        }
        .deals-list p{
            font-size: 11px
        }
    }
</style>