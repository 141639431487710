import { defineStore } from 'pinia'
export const useEmployeesStore = defineStore('employees', {
  state: () => ({ 
    // businessList:[]
  }),
  actions: {
    async getEmployees(params={}) {
      let url = 'employees_list'
      if ('business_id' in params || 'slug' in params) {
          url = 'get_employees_list'
      }
      const resp = await this.axios.get(url,{params:params}).catch(error => {return error.response})
      return resp.data
    },

    // Filters Work
    // updateFilters(route,filters = null){
    //   if (filters === null) {
    //     for (const key in route.query) {
    //       this.filters_[key] = route.query[key]
    //     }
    //   }else{
    //     let query = {
    //       sort_by: filters.sort_by,
    //       render: filters.render,
    //     }
    //     filters.rating > 0 ? query.rating=filters.rating : ''
    //     filters.currency_range !== null  ? query.currency_range=filters.currency_range : ''
    //     filters.isOpen ? query.isOpen = true : ''
    //     filters.hasDeals ? query.hasDeals = true : ''
    //     filters.gender !== '' ? query.gender = filters.gender : ''
    //     filters.distance > 0 ? query.distance=filters.distance : ''
    //     this.router.push({ name: "Listing", query: query,params:route.params });
    //     this.getBusinesses(route.params)
    //   }
    // },
    // clearFilters(){
    //   const emptyFilters = {
    //     currency_range: null,
    //     isOpen: false,
    //     hasDeals: false,
    //     rating: 0,
    //     gender: "",
    //     distance: 0,
    //   }
    //   this.filters_ = { ...this.filters_, ...emptyFilters };
    // }
  }
})