<template>
<div>
    <!-- Back Button For Mobile View -->
    <div class="row mb-2 d-block d-sm-none">
        <div class="col-12">
            <a href="javascript:void(0)" v-on:click.prevent="router.push({name:'BusinessProfile'})" class="text-dark"><fa icon="angle-left" /> Back</a>
        </div>
    </div>

    <div class="overview-onbox" v-if="businessInfo.businessIsOpen == 1 && businessInfo.queue_status == 1  ">
        <!-- Queue Header  -->
        <div class="row mb-sm-4 mb-5" v-show="!addInQueue">
            <!-- Live queue / Join waitlist -->
            <div class="col-12 bg-white pt-3 pb-3 pb-md-0" v-if="businessInfo.queue_walkin == 0 && businessInfo.out_of_range == 0" >
                <div  class="float-start queue_circle"></div>
                <div class="float-start">
                    <h4 v-if="activeEmployee.user">Live Queue - {{activeEmployee.user.name}} ({{queues.length}})</h4>
                    <h4 v-else>Live Queue </h4>
                    <p>We are open and accepting clients</p>
                </div>
                <div class="float-md-end">
                    <button type="button" class="btn btn-secondary" style="margin-right: 10px;" >
                        <img class="img-fluid" src="@/assets/images/icons/refresh.svg" alt="" />
                    </button>
                    <button v-if="!storage.getStorageSync('token')" type="button" class="ond-btn-p" style="padding: 12px 35px; font-size: 13px" data-bs-toggle="modal" data-bs-target="#loginModal">Join the waitlist</button>
                    <button v-else class="ond-btn-p" style="padding: 12px 35px; font-size: 13px" v-on:click="setJoinQueue(true)"> Join the waitlist</button>
                </div>
            </div>

            <!-- Out of range -->
            <div class="col-12 bg-white pt-3 pb-3 pb-md-0" v-if="businessInfo.queue_walkin == 0 && businessInfo.out_of_range == 1">
                <div  class="float-start queue_circle-closed"></div>
                <div class="float-start" >
                    <h4 v-if="activeEmployee.user">Out of Range - {{activeEmployee.user.name}} ({{queues.length}})</h4>
                    <h4 v-else>Out of Range </h4>
                    <p>You are outside the range. We only offers queue services within a 10-mile radius.</p>
                </div>
            </div>

            <!-- Walkin-In Only -->
            <div class="col-12 bg-white pt-3 pb-3 pb-md-0" v-if="businessInfo.queue_walkin == 1">
                <div  class="float-start queue_circle-walk-in"></div>
                <div class="float-start"  >
                    <h4 v-if="activeEmployee.user">Walk-in Queue - {{activeEmployee.user.name}} ({{queues.length}})</h4>
                    <h4 v-else>Walk-in Queue </h4>
                    <p>We are offer only walk-in queues</p>
                </div>
            </div>

        </div>
        <!-- Queue Body -->
        <div class="overview-tabs-detail" v-show="!addInQueue">
            <!-- Employees List Carousel -->
            <div class="row emp-carousel bg-white pt-3" v-if="employees.length > 0">
                <Carousel :items-to-show="1" :itemsToScroll="1" :breakpoints="employeeCarouselBreakPoint" class="empl-carousel" id="queueEmployeeCarousel">
                    <Slide v-for="(employee) in employees" :key="employee.id">
                        <div class="customer-appointment bg-light p-2 px-sm-3" @click="getEmployeQueue(employee)">
                            <div class="customer-image">
                                <img v-if="!employee.user.picture" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/user-icon.jpg" />
                                <img v-if="employee.user.picture" :src="employee.user.picture" />
                                <div class="gender-tag">
                                    <!-- <img src="@/assets/images/icons/sm.png" /> -->
                                    M
                                </div>
                            </div>
                            <div class="customer-detail text-start">
                                <h4 class="name">{{ employee.user.name }}</h4>
                                <!-- <div class="category">{{ employee.profession.title }}</div> -->
                                <a href="#" class="service" data-bs-toggle="modal" data-bs-target="#empServicessModal" @click="getServices(employee)">View services</a>
                                <div class="waiting-time" v-if="employee.estTime">Est. wait time ({{ employee.estTime }})</div>
                            </div>
                        </div>
                    </Slide>
                    <template #addons>
                        <Navigation />
                    </template>
                </Carousel>
            </div>
            <!-- Queues List Carousel -->
            <div class="row bg-white mt-3" v-if="employees.length > 0">
                <div class="col-lg-12">
                    <div class="queue-arousel" v-if="queues.length > 0">
                        <Carousel :items-to-show="3" :itemsToScroll="3" :breakpoints="queueCarouselBreakPoint" class="queues-arousel">
                            <Slide v-for="(queue,index) in queues" :key="queue.id">
                                <div class="item">
                                    <div class="pad15">
                                        <div class="bk-image">
                                            <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/user-icon.jpg" />
                                        </div>
                                        <div class="booking-time">O{{ index + 1 }}</div>
                                        <div class="booking-id">{{ getLastFourNumbers(queue.tracking_id) }}</div>
                                    </div>
                                </div>
                            </Slide>
                            <template #addons>
                                <Navigation />
                            </template>
                        </Carousel>
                    </div>
                    <div v-show="queues.length === 0" class="text-center mt-4">
                        <h6 class="fw-normal">Only queue when you're less than 5 minutes away.</h6>
                    </div>
                </div>
            </div>
            <!-- No employees alert -->
            <div class="text-center mt-4" v-show="employees.length === 0">
                <h6>Sorry! No professional available for queue.</h6>
            </div>
        </div>
        <!-- Join Queue Section -->
        <div class="overview-tabs-detail" v-show="addInQueue">
            <div class="row" v-if="step == 1 && employees.length > 0" >
                <div class="col-12">
                    <h5>Choose a Professional</h5>
                </div>
                <div class="col-12 col-md-6 mb-2" v-for="emp in employees" :key="emp.id" >
                    <div class="card emp-card" v-on:click="getServices(emp)" :class="queue_.employee.id == emp.id ? 'active':''">
                        <div class="card-body">
                            <div class="booking-vendor-img" style="background: #0000 url('https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/user-icon.jpg') repeat scroll 50% 50% / cover padding-box border-box;"></div>
                            <div id="DIV_10">
                                <a href="javascript:void(0);" id="A_11">{{emp.user.name}}</a>
                                <div id="DIV_12">
                                    <span id="SPAN_14" ng-if="slot.service">Barber</span>  <br>
                                    <span id="SPAN_14" v-if="emp.estTime">Est. wait time ({{emp.estTime}})</span>            
                                </div>
                            </div>
                        </div>
                        <fa icon="check-circle" class="fa-lg" />
                    </div>
                </div>
                <div class="col-12 mb-2">
                    <div class="row mt-3">
                        <label class="mb-2" v-if="queue_.services.length > 0">Select Services</label>
                        <div v-for="(service,index) in queue_.services" :key="index" class="form-group col-md-6">
                            <label class="con-company" :class="service.selected?'currnt':''">
                                <span>{{service.title}}</span>
                                <span class="float-end ms-5">${{service.cost}}</span>
                                <span class="float-end">{{service.duration}} min</span>
                                <input v-model="service.selected" :true-value="true" :false-value="false" type="checkbox" name="checkbox" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-show="step == 2" >
                <div class="col-12">
                    <h5>Summary</h5>
                </div>
                <div class="col-12">
                    <div class="leads-table-area report-table-area" style="box-shadow: none;">
                        <table class="display" id="servicesTable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Duration</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody v-if="queue_.services.length > 0">
                                <tr v-for="(serv,index) in selectedServices" :key="serv.id">
                                    <td>{{index+1}}</td>
                                    <td>{{serv.title}}</td>
                                    <td>{{serv.duration}} min</td>
                                    <td>${{serv.cost}}</td>
                                </tr>
                                <tr v-show="selectedServices.length == 0">
                                    <td colspan="4" class="text-center">No Service</td>
                                </tr>
                            </tbody>
                            <tfoot v-if="queue_.services.length > 0">
                                <tr v-show="selectedServices.length > 0">
                                    <th colspan="3" class="text-end">Total:</th>
                                    <th>${{selectedServicesSum}}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <h5>Choose Payment Card</h5>
                </div>
                <StripeCard :cardData="updateCard" />
            
            </div>
            <div class="row">
                <div class="col-12 py-3 border-top">
                    <button class="ond-btn-p float-end" v-on:click="addQueue" v-if="step == 2">Join</button>
                    <button class="ond-btn-p float-end" v-on:click="updateStep(2)" v-if="step == 1">Continue</button>
                    <button class="ond-btn-w me-3 float-end" v-on:click="updateStep(1)" v-if="step == 2">Back</button>
                    <button class="ond-btn-w float-start" v-on:click="setJoinQueue(false)">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="overview-onbox" v-if="businessInfo.businessIsOpen == 0 ">
        <div class="row mb-sm-4 mb-5" v-show="!addInQueue">
            <div class="col-12 bg-white pt-3 pb-3 pb-md-0">
                <div class="float-start queue_circle-closed"></div>
                <div class="float-start">
                    <h4 >Business is closed </h4>
                    <p>We are not accepting queues today</p>
                </div>
            </div>
        </div>
    </div>
    <div class="overview-onbox" v-if="businessInfo.businessIsOpen == 1 && businessInfo.queue_status == 0 ">
        <div class="row mb-sm-4 mb-5" v-show="!addInQueue">
            <div class="col-12 bg-white pt-3 pb-3 pb-md-0">
                <div class="float-start queue_circle-closed"></div>
                <div class="float-start">
                    <h4 >Queue Disabled </h4>
                    <p>We are not accepting queues </p>
                </div>
            </div>
        </div>
    </div>
    <!-- Employe service Modal -->
    <div class="modal fade" id="empServicessModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="servicsModal" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="signupModalLabel"> {{ empServicesModalTitle }} -  Services </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Service Title</th>
                                    <th>Duration</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(serv,index) in empServices" :key="serv.id">
                                    <td>{{index+1}}</td>
                                    <td>{{serv.title}}</td>
                                    <td>{{serv.duration}} min</td>
                                    <td>${{serv.cost}}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
    <!-- End Services modal -->
</div>
</template>
<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import $ from 'jquery'
import axios from 'axios'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import "datatables.net"
import "datatables.net-responsive"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css"
import StripeCard from '@/components/common/StripeCard.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStorage } from "vue3-storage";


const props = defineProps({ 
    employees:Array,
    businessInfo:Object
});
// Data Variables
const route = useRoute()
const router = useRouter()
const storage = useStorage()

const addInQueue = ref(false)
const queues = ref([])
const empServices = ref([])
const empServicesModalTitle = ref('')
const activeEmployee = ref({})
const step = ref(1)
let queue_ = reactive({
    employee:{},
    card:'',
    services:[],
    slug: route.params.slug,
    booking_source:'online',
    note:''
})

// console.log(props.businessInfo,props.employees); 

onMounted(() => {
    if (props.employees.length > 0) {
        getEmployeQueue(props.employees[0])
    }

    $("#servicesTable").addClass("nowrap").dataTable({
        ordering: false,
        searching: false, 
        paging: false, 
        info: false,
        responsive: true,
        fixedHeader: true,
        language : {
            "zeroRecords": " "             
        }
    });
})


// Methods
async function getEmployeQueue(emp){
    if(emp){
        activeEmployee.value = emp
        const resp = await axios.post('get_employee_queues',{'provider_id':emp.id,'user_id':emp.user_id,'slug':route.params.slug})
        if (resp.data.status === 'success') {
            queues.value = resp.data.data
        }
    }
}
async function getServices(emp){
    if(emp){
        queue_.employee = emp
        empServices.value = []
        empServicesModalTitle.value = emp.user.name

        const resp = await axios.post('queueServices',{'user_id':emp.user.id,'business':props.businessInfo.id})
        if (resp.data.status === 'success') {
            var data = []
            resp.data.data.forEach((serv) => {
                data.push({
                    title:serv.service.title,
                    service_id: serv.service_id,
                    business_service_id:serv.business_service_id,
                    employee_service_id: serv.id,
                    category_id: serv.service.category_id,
                    cost: serv.cost,
                    duration: serv.duration,
                    selected : false,
                })
            });
            queue_.services = data
            empServices.value = data
            console.log(queue_.services)
        }
    }
}
async function addQueue(){
    console.log(queue_)
    const resp = await axios.post('join_queue',queue_)
    if (resp.data.status === 'success') {
        getEmployeQueue(activeEmployee)
        setJoinQueue(false)
        updateStep(1)
        queue_={
            employee:{},
            card:'',
            services:[],
            slug:route.params.slug,
            booking_source:'online',
            note:''
        }
    }
}
function setJoinQueue(status){
    addInQueue.value = status
}
function updateStep(value){
    step.value = value
}
function getLastFourNumbers(str) {
    return str.slice(-4);
}
function updateCard(data){
    queue_.card = data
}
// Carousel
const employeeCarouselBreakPoint = {
    575: {
        itemsToShow: 2,
        itemsToScroll:2,
        snapAlign: 'start',
    },
    768: {
        itemsToShow: 3,
        itemsToScroll:3,
        snapAlign: 'start',
    },
    992: {
        itemsToShow: 3,
        itemsToScroll:3,
        snapAlign: 'start',
    },
    1200: {
        itemsToShow: 3,
        itemsToScroll: 3,
        snapAlign: 'start',
    }
}
const queueCarouselBreakPoint = {
    575: {
        itemsToShow: 4,
        itemsToScroll: 4,
        snapAlign: 'start',
    },
    // 700px and up
    992: {
        itemsToShow: 5,
        itemsToScroll: 5,
        snapAlign: 'start',
    },
    // 1024 and up
    1200: {
        itemsToShow: 5,
        itemsToScroll: 5,
        snapAlign: 'start',
    }
}

//Computed 
const selectedServices = computed(() => {
    return queue_.services.filter(i => i.selected === true)
})
const selectedServicesSum = computed(() => {
    var data = queue_.services.filter(i => i.selected === true)
    return data.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0).toFixed(2);
})
</script>
    
<style>
    .dataTables_empty{
        display: none
    }
    .emp-card {
        cursor: pointer;
        border: 1px solid #f1f1f1
    }
    .emp-card svg {
        display: none
    }
    .emp-card.active {
        border: 2px solid #6e317a;
        background: #6e317a0f
    }
    .emp-card.active svg {
        display: block;
        position: absolute;
        right: -7px;
        top: -7px;
        color: #6e317a;
        z-index: 23;
        background: #fff
    }
    .booking-vendor-img {
        background-position: 50% 50%;
        cursor: pointer;
        float: left;
        height: 60px;
        text-decoration: none solid rgb(0, 0, 238);
        text-size-adjust: 100%;
        width: 60px;
        border: 0px none rgb(0, 0, 238);
        border-radius: 100% 100% 100% 100%;
        flex: 0 0 auto;
        font: normal normal 300 normal 15px / 20px proxima-nova, sans-serif;
        margin: 0px 15px 0px 0px;
        outline: rgb(0, 0, 238) none 0px;
    }
    #DIV_10 {
        color: rgb(51, 51, 51);
        display: flex;
        min-height: auto;
        min-width: auto;
        text-decoration: none solid rgb(51, 51, 51);
        text-size-adjust: 100%;
        width: calc(100% - 95px);
        column-rule-color: rgb(51, 51, 51);
        align-items: flex-start;
        perspective-origin: 442.5px 47.5px;
        transform-origin: 442.5px 47.5px;
        caret-color: rgb(51, 51, 51);
        border: 0px none rgb(51, 51, 51);
        flex: 1 1 0%;
        flex-flow: column nowrap;
        font: normal normal 300 normal 15px / 20px proxima-nova, sans-serif;
        outline: rgb(51, 51, 51) none 0px;
    }
    #DIV_10 a span{
        font-size: 12px;
    }
    #A_11 {
        
        color: rgb(51, 51, 51);
        display: block;
        text-decoration: none solid rgb(51, 51, 51);
        text-size-adjust: 100%;
        border: 0px none rgb(51, 51, 51);
        font: normal normal 400 normal 18px / 20px proxima-nova, sans-serif;
        margin: 0px 0px 3px;
        outline: rgb(51, 51, 51) none 0px;
    }
    #DIV_12 {
        
        color: rgba(51, 51, 51, 0.8);
        min-height: auto;
        min-width: auto;
        text-decoration: none solid rgba(51, 51, 51, 0.8);
        text-size-adjust: 100%;
        width: 183.047px;
        column-rule-color: rgba(51, 51, 51, 0.8);
        perspective-origin: 91.5156px 10.5px;
        transform-origin: 91.5156px 10.5px;
        caret-color: rgba(51, 51, 51, 0.8);
        border: 0px none rgba(51, 51, 51, 0.8);
        font: normal normal 400 normal 15px / 20px proxima-nova, sans-serif;
        outline: rgba(51, 51, 51, 0.8) none 0px;
    }

    #SPAN_13 {
        
        color: rgb(149, 152, 154);
        display: inline-block;
        height: 20px;
        text-align: right;
        text-decoration: line-through solid rgb(149, 152, 154);
        text-size-adjust: 100%;
        width: 38.6406px;
        column-rule-color: rgb(149, 152, 154);
        perspective-origin: 19.3125px 10px;
        transform-origin: 19.3125px 10px;
        caret-color: rgb(149, 152, 154);
        border: 0px none rgb(149, 152, 154);
        font: normal normal 400 normal 12px / 20px proxima-nova, sans-serif;
        outline: rgb(149, 152, 154) none 0px;
    }
    #SPAN_14, #SPAN_15 {
        
        color: rgba(51, 51, 51, 0.8);
        text-decoration: none solid rgba(51, 51, 51, 0.8);
        text-size-adjust: 100%;
        column-rule-color: rgba(51, 51, 51, 0.8);
        perspective-origin: 0px 0px;
        transform-origin: 0px 0px;
        caret-color: rgba(51, 51, 51, 0.8);
        border: 0px none rgba(51, 51, 51, 0.8);
        font: normal normal 400 normal 14px / 20px proxima-nova, sans-serif;
        outline: rgba(51, 51, 51, 0.8) none 0px
    }
    .booking-date{
        padding: 10px 20px
    }
    .booking-vendor{
        position: relative
    }
    .carousel__viewport{
        overflow-x: clip !important;
        overflow-y: unset !important
    }
    .con-company {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 0;
        cursor: pointer;
        font-size: 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid #ccc;
        padding: 6px 6px 6px 35px;
        border-radius: 3px
    }
    .con-company.currnt {
        border: 1px solid #3151A1;
        background: #F7F9FF;
    }
    .con-company input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0
    }
    .con-company .checkmark {
        position: absolute;
        top: 9px;
        left: 10px;
        height: 14px;
        width: 14px;
        background-color: #fff;
        border: 1px solid #A2A8B8;
        border-radius: 3px
    }
    .con-company input:checked~.checkmark {
        background-color: #3151A1;
        border: 1px solid #3151A1;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .con-company .checkmark:after {
        left: 4px;
        top: 1px;
        width: 5px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .con-company input:checked~.checkmark:after {
        display: block;
    }
    table.dataTable tfoot th, table.dataTable tfoot td{
        padding: 15px 10px
    }
    table.dataTable {
        width: 100% !important;
    }
    #queueEmployeeCarousel .carousel__slide{
        padding: 1rem 0 !important
    }
    .carousel__track > li.carousel__slide--visible ~ li.carousel__slide--visible {
        border-left: none
    }
    .queue_circle{
        background-color: #0e8e32;
        width: 15px;
        height: 15px;
        border-radius: 50px;
        margin: 7px 10px 0 0
    }
    .queue_circle-walk-in{
        background-color: #FFA500;
        width: 15px;
        height: 15px;
        border-radius: 50px;
        margin: 7px 10px 0 0
    }
    .queue_circle-closed{
        background-color: #b9b8b6;
        width: 15px;
        height: 15px;
        border-radius: 50px;
        margin: 7px 10px 0 0
    }
    .ond-btn-w{
        padding: 8px 20px !important
    }

    .customer-appointment:hover{
        box-shadow: 0 0 6px rgb(51 51 51 / 15%)
        /*box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important*/
        /*box-shadow: 0px 0px 23px rgb(54 54 54 / 40%)*/
    }
    @media (min-width:768px) {
        .empl-carousel .carousel__prev, .empl-carousel .carousel__next{
            display: none
        }
        .emp-carousel:hover .empl-carousel .carousel__prev, .emp-carousel:hover .empl-carousel .carousel__next{
            display: block
        }
        .queues-arousel .carousel__prev, .queues-arousel .carousel__next{
            display: none
        }
        .queue-arousel:hover .queues-arousel .carousel__prev, .queue-arousel:hover .queues-arousel .carousel__next{
            display: block
        }
    }
</style>
<style scoped>
    .overview-onbox{
        background-color: #f5f5f5
    }
    @import "../../assets/css/dashboard/dataTables.css";
</style>