<template>
<div class="modal fade" id="shareModal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body" >
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h4> Share your Business</h4>
                        </div>
                        <div class="col-md-12 text-center">
                            <p> Spread your business arround the globe </p>
                        </div>
                    </div>
                    <div class="row" style="padding-top: 10px; padding-bottom: 10px;">
                        <div class="col-3 text-center">
                            <ShareNetwork
                                network="facebook"
                                url={{currentUrl}}
                                title="Hello! this is my business on ONDAQ"
                                description="You can visit my business profile by click on the link"
                                hashtags="Ondaq"
                            >
                                <img src="../../assets/images/social/fb.png" />
                            </ShareNetwork>
                        </div>
                        <div class="col-3 text-center">
                            <ShareNetwork
                                network="linkedIn"
                                url={{currentUrl}}
                                title="Hello! this is my business on ONDAQ"
                                description=""
                            >
                                <img src="../../assets/images/social/lin.png" />
                            </ShareNetwork>
                        </div>
                        <div class="col-3 text-center">
                            <ShareNetwork
                                network="twitter"
                                url={{currentUrl}}
                                title="Hello! this is my business on ONDAQ"
                                description=""
                                hashtags="Ondaq"
                            >
                                <img src="../../assets/images/social/tw.png" />
                            </ShareNetwork>
                        </div>
                        <div class="col-3 text-center">
                            <ShareNetwork
                                network="whatsapp"
                                url={{currentUrl}}
                                title="Hello! this is my business on ONDAQ"
                            >
                                <img src="../../assets/images/social/wa.png" />
                            </ShareNetwork>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import { ref } from 'vue'
let currentUrl = ref('')
currentUrl.value = window.location.href;
</script>
