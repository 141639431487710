<template>
    <div class="modal fade" id="requestModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Request Services</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Description</label>
                                <textarea v-model="store.request_.description" class="form-control h-auto" :class="[v$.description.$error ? 'border-danger': '']" rows="7"  placeholder="Hi,&#10;I hope you're well.&#10;I want to book an appointment for full makeup for my friends and me.&#10;How much is the cost for three people?&#10;Is there availability for July 18, 2022, at 10 AM?&#10;Thank you, and I look forward to hearing from you."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Professional </label>
                                <select v-model="store.request_.employee" :class="[v$.employee.$error ? 'border-danger': '']" class="form-control" v-on:change="getServices">
                                    <option value="">Choose employee</option>
                                    <option v-for="employee in employees" :key="employee.id" :value="employee">{{employee.user.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="font-weight-normal">Where do you want service rendered?</label>
                                <select class="form-select" v-model="store.request_.render_location" :class="[v$.render_location.$error ? 'border-danger': '']">
                                    <option value="">Choose render location</option>
                                    <option v-if="renderAtBusiness" value="business">At Business</option>
                                    <option v-if="renderAtClient" value="client">Client Location</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <label for="inputPassword4">Select Professional </label>
                            <select v-model="request_.employee" :class="[v$.request_.employee.$error ? 'border-danger': '']" class="form-control" v-on:change="getServices">
                                <option value="">Choose employee</option>
                                <option v-for="employee in employees" :key="employee.id" :value="employee">{{employee.user.name}}</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="row">
                        <div v-for="(service,index) in store.request_.services" :key="service.id" class="form-group col-sm-6 col-md-6 col-lg-3">
                            <label><span v-if="index == 0">Select Services</span> </label>
                            <label class="con-company" :class="service.selected?'currnt':''">
                                {{service.title}}
                                <input v-model="service.selected" :class="[v$.services.$error ? 'border-danger': '']" :true-value="true" :false-value="false" type="checkbox" name="checkbox" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Date</label>
                                <Datepicker :input-class-name="v$.request_date.$error ? 'border-danger': ''" :minDate="new Date()" v-model="store.request_.request_date"  :enableTimePicker="false" :autoApply="true" placeholder="Select Date" ></Datepicker>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="font-weight-normal">Price</label>
                                <div class="input-group">
                                    <span class="input-group-text">$</span>
                                    <input type="text" class="form-control" :class="[v$.price.$error ? 'border-danger': '']" v-model="store.request_.price" placeholder="Enter price">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="ond-btn-w" data-bs-dismiss="modal">Cancel</button>
                    <a href="#" class="ond-btn-p" v-on:click.prevent="sendRequest">Send</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref,computed } from 'vue'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import useVuelidate from '@vuelidate/core'
import { required, integer } from '@vuelidate/validators'
import { useToast } from "vue-toastification"
import $ from 'jquery'

import { useBookingStore } from '@/stores/booking'
import { useBusinessStore } from '@/stores/business'
import { useEmployeesStore } from '@/stores/employees'
import { useServicesStore } from '@/stores/services'
import { useRoute } from 'vue-router'
import { useStorage } from "vue3-storage";

const store = useBookingStore()
const businessStore = useBusinessStore()
const employeeStore = useEmployeesStore()
const servicesStore = useServicesStore()
const route = useRoute()
const employees = ref([])
const storage = useStorage()
const toast = useToast();

const formData = store.request_
const rules = {
    description:{ required },
    request_date:{ required },
    employee:{ required },
    services:{ required },
    render_location:{ required },
    price:{ required, integer }
}
const v$ = useVuelidate(rules, formData)

store.request_.slug = route.params.slug


const getEmployees = async () => {
    const resp = await employeeStore.getEmployees()
    // console.log(resp)
    if (resp.status === 'success') {
        employees.value = resp.data
    }else{
        toast.error(resp.message);
    }  
}
if (storage.getStorageSync('token')) {
    getEmployees()
}

const getServices = async () => {
    const resp = await servicesStore.getEmployeeServices(store.request_.employee.user.id)
    // console.log(resp)
    if (resp.status === 'success') {
        var data = []
        resp.data.forEach((serv) => {
            data.push({ 
                title: serv.service.title, 
                business_id: serv.business_id, 
                employee_id: serv.id, 
                service_id: serv.service.id,
                cost: serv.business_service.cost,
                duration: serv.business_service.duration,
                business_service_id: serv.business_service.id,
                selected: false
            })
        });
        store.request_.services = data
    }else{
        toast.error(resp.message);
    }  
}

const renderAtBusiness = computed(() => {
    if(businessStore.businessDetail.business_info.service_render_location){
        return businessStore.businessDetail.business_info.service_render_location.includes("business")
    }
    return false
})
const renderAtClient = computed(() => {
    if(businessStore.businessDetail.business_info.service_render_location){
        return businessStore.businessDetail.business_info.service_render_location.includes("client")
    }
    return false
})

const isLoading = ref(false);
const sendRequest = async () => {
    console.log(store.request_)
    v$.value.$touch()
    if (!v$.value.$error) {
        isLoading.value = true
        const resp = await store.sendRequestedQuote()
        //console.log(resp)
        if (resp.status === 'success') {
            toast.success("Quote successfully send.");
            $("#requestModal").model('hide')
            // thiss.$router.push({ name: 'BusinessProfile'})
            // $(".btn-close").click()
        }else{
            toast.error(resp.message);
        }
        isLoading.value = false
    }
}

</script>
<style scoped>
textarea.form-control::placeholder { 
  color: #e7e7e7 !important;  
}
</style>