import { defineStore } from 'pinia'
export const useServicesStore = defineStore('services', {
  state: () => ({ 
    // businessList:[]
  }),
  actions: {
    async getEmployeeServices(id) {
      const resp = await this.axios.post("employeeServices",{id: id}).catch(error => {return error.response})
      return resp.data
    }
  }
})