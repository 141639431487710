<template>
    <div>
        <div class="sidebar-box d-lg-none d-block">
            <safety-precautions />
        </div>
        <div class="overview-onbox" id="overview">
            <div class="about-salon" v-if="store.businessDetail.business_info">
                <div class="row" v-if="store.businessDetail.business_info.description">
                    <div class="col-12 mt-2">
                        <h3>Description</h3>
                        <p v-if="store.businessDetail.business_info.description.length > 525"><span>{{store.businessDetail.business_info.description.substring(0, 525)}}</span><span v-if="readMore">{{businessInfo.description.substring(525, 4000)}}</span> <a href="#!" @click="readMore =! readMore"><span v-if="readMore">See Less</span><span v-else>See More</span></a></p>
                        <p v-else>{{store.businessDetail.business_info.description}}</p>
                    </div>
                </div>
                <div class="row" v-if="store.businessDetail.business_info.facilities">
                    <div class="col-12 mt-2">
                        <h3>Facilities</h3>
                    </div>
                    <div class="col-md-6" v-for="(facility,index) in splitFacilities" :key="index">
                        <p class="avalaibilty">
                            <img v-if="splitFacilitiesChild(facility,0) == 'Free Parking'" src="@/assets/images/icons/parking.png">
                            <img v-if="splitFacilitiesChild(facility,0) == 'Free Wifi'" src="@/assets/images/icons/wifi.png">
                            <img v-if="splitFacilitiesChild(facility,0) == 'Reception'" src="@/assets/images/icons/rest-area.png">
                            <img v-if="splitFacilitiesChild(facility,0) == 'Kids Friendly'" src="@/assets/images/icons/kids.png">
                            <img v-if="splitFacilitiesChild(facility,0) == 'Languages'" src="@/assets/images/icons/language.png">
                            <img v-if="splitFacilitiesChild(facility,0) == 'Payment methods'" src="@/assets/images/icons/credit_card.png">
                            <!-- {{facility.replace(/_/g,', ')}} -->
                            <span v-if="splitFacilitiesChild(facility,1) == 'Yes'">{{splitFacilitiesChild(facility,0)}} <fa icon="check" /></span>
                            <span v-else-if="splitFacilitiesChild(facility,1) == 'No'">{{splitFacilitiesChild(facility,0)}} <fa icon="times" /></span>
                            <span style="margin-left:0;" v-else>{{splitFacilitiesChild(facility,0)}}: {{splitFacilitiesChild(facility,1).replace(/_/g,', ')}}</span>
                        </p>
                    </div>
                    <!-- <div class="col-md-6">
                        <p v-if="businessInfo.description.length > 525"><span>{{businessInfo.description.substring(0, 525)}}</span><span v-if="readMore">{{businessInfo.description.substring(525, 4000)}}</span> <a href="#!" @click="readMore =! readMore"><span v-if="readMore">See Less</span><span v-else>See More</span></a></p>
                        <p >{{businessInfo.facilities.split(",")}}</p>
                        <p >{{businessInfo.facilities.split(",")[0]}}</p>
    
                        <p class="avalaibilty"><img src="@/assets/images/icons/parking.png"> Free Parking</p>
                    </div>
                    <div class="col-md-6">
                        <p >{{businessInfo.facilities.split(",")}}</p>
                        <p >{{businessInfo.facilities.split(",")[1]}}</p>
                        <p class="avalaibilty"><img src="@/assets/images/icons/phone.png"> {{phoneFormate(businessInfo.phone)}}</p>
                    </div>
                    <div class="col-md-6">
                        <p >{{businessInfo.facilities.split(",")[2]}}</p>
                        <p class="avalaibilty"><img src="@/assets/images/icons/wifi.png"> Wifi</p>
                    </div>
                    <div class="col-md-6">
                        <p >{{businessInfo.facilities.split(",")[3]}}</p>
                        <p class="avalaibilty"><img src="@/assets/images/icons/language.png"> English, French</p>
                    </div>
                    <div class="col-md-6">
                        <p >{{businessInfo.facilities.split(",")[4]}}</p>
                        <p class="avalaibilty"><img src="@/assets/images/icons/rest-area.png"> Reception</p>
                    </div>
    
                    <div class="col-md-6">
                        <p >{{businessInfo.facilities.split(",")[5]}}</p>
                        <p class="avalaibilty"><img src="@/assets/images/icons/credit_card.png"> Accepts Creditcard, Cash</p>
                    </div>  -->
                    <!-- <div class="col-md-6">
                        <p class="avalaibilty"><img src="@/assets/images/icons/kids.png"> Kids friendly</p>
                    </div> -->
                </div>
                <div class="row" v-if="store.businessDetail.business_info.special_instructions">
                    <div class="col-12  mt-3">
                        <h3>Special Instructions</h3>
                        <p>{{store.businessDetail.business_info.special_instructions}} </p>
                    </div>
                </div>
                <!-- <div class="row" v-if="businessInfo.cancellation_policy">
                    <div class="col-12  mt-3">
                        <h3>Appointment Cancellation Policy</h3>
                        <p>{{businessInfo.cancellation_policy}} </p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref,computed } from 'vue'
import SafetyPrecautions from '@/components/businessProfile/SafetyPrecautions'
import { useBusinessStore } from '@/stores/business'
const store = useBusinessStore()
const readMore = ref(false);

const splitFacilitiesChild = (fac,index) => {
    return fac.split(": ")[index]
}

const splitFacilities = computed(() => {
    if(store.businessDetail.business_info.service_render_location){
        return store.businessDetail.business_info.facilities.split(",")
    }
    return false
})
// const phoneFormate = (str,withCountryCode=false) => {
//     //Filter only numbers from the input
//     let cleaned = ('' + str).replace(/\D/g, '');
//     let match1 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
//     let match2 = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
//     if (match1) {
//         return '(' + match1[1] + ') ' + match1[2] + '-' + match1[3]
//     }else if(match2){
//         if(withCountryCode){
//             let intlCode = (match2[1] ? '+1 ' : '')
//             return [intlCode, '(', match2[2], ') ', match2[3], '-', match2[4]].join('')
//         }else{
//             return ['(', match2[2], ') ', match2[3], '-', match2[4]].join('')
//         }
//     }
//     return str
// }

</script>

<style scoped>
    .sidebar-box {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 20px
    }
    .sidebar-box img {
        margin-left: -8px;
    }
    .avalaibilty svg{
        margin-left: 10px;
    }
</style>