<template>
    <div class="sidebar-right mt-4 mt-lg-0">
        <div class="sidebar-box d-lg-block d-none" v-if="$route.params.tab != 'services' && $route.params.tab != 'queue'">
            <safety-precautions />
        </div>
        <div class="sidebar-box">
            <div class="location-map">
                <div ref="map" style="height:300px"></div>
                <div class="contact-detail" v-if="businessInfo">
                    <p><fa icon="phone-alt" /> {{phoneFormate(businessInfo.phone)}}</p>
                    <p><fa icon="map-marker-alt" /> {{businessInfo.street}}, {{businessInfo.city}}, {{businessInfo.state}} {{businessInfo.zip}}</p>
                </div>
            </div>
        </div>
        <div class="sidebar-box" v-if="businessInfo && $route.params.tab != 'services' && $route.params.tab != 'queue'">
            <div class="title">
                <h4 class="m-0">Business Hours</h4>
            </div>
            <div class="bs-hours">
                <div class="days" v-for="bHour in businessInfo.business_hours" :key="bHour.id" :class="bHour.day == currentDay?'active':''">
                    <span class="day-name">{{bHour.title}}</span>
                    <span class="day-status closed" v-if="bHour.isOpen == 0">Closed</span>
                    <span class="day-status" v-if="bHour.isOpen == 1">{{convertTo12(bHour.start_time)}} - {{convertTo12(bHour.end_time)}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import SafetyPrecautions from '@/components/businessProfile/SafetyPrecautions'
</script>

<script>
export default {
    props: {businessInfo:Object},
    data() {
        return {
            currentDay:'',
            mapStatus:true
        }
    },
    created(){
        var us_date = new Date().toLocaleString('en-US', { day:'numeric',month:'numeric',year:'numeric',timeZone: 'America/New_York' });
        var cDay =  new Date(us_date).getDay();
        if (cDay == 0) {
            this.currentDay = 7
        }else{
            this.currentDay = cDay
        }
        let thiss = this
        setTimeout(function(){
            thiss.drawMap()
        }, 3000);
        //this.drawMap()
        //console.log(this.businessInfo)
    },
    methods:{
        convertTo12(time_str){
            var date = new Date("February 04, 2011 "+time_str);
            var options = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            };
            return date.toLocaleString('en-US', options);
        },
        drawMap(){
            const mapOptions = {
                center: {lat: parseFloat(this.businessInfo.lat), lng: parseFloat(this.businessInfo.lng)},
                zoom: 15,
                zoomControl:true,
                zoomControlOptions:{
                    position: window.google.maps.ControlPosition.LEFT_TOP
                },
                mapTypeControl: false, 
                fullscreenControl: false, 
                streetViewControl: false,
                styles: [
                    {
                        featureType: 'poi',
                        stylers: [{visibility: "off"}]
                    }
                ]
            };
            const map = new window.google.maps.Map(this.$refs["map"], mapOptions);
            new window.google.maps.Marker({
                position: {lat: parseFloat(this.businessInfo.lat), lng: parseFloat(this.businessInfo.lng)},
                map,
                title: this.businessInfo.address,
            });
            this.mapStatus = false
        },
        phoneFormate(str,withCountryCode=false){
            //Filter only numbers from the input
            let cleaned = ('' + str).replace(/\D/g, '');
            let match1 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            let match2 = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match1) {
                return '(' + match1[1] + ') ' + match1[2] + '-' + match1[3]
            }else if(match2){
                if(withCountryCode){
                    let intlCode = (match2[1] ? '+1 ' : '')
                    return [intlCode, '(', match2[2], ') ', match2[3], '-', match2[4]].join('')
                }else{
                    return ['(', match2[2], ') ', match2[3], '-', match2[4]].join('')
                }
            }
            return str
        }
    }
}
</script>