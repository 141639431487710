<template>
    <MainAppContainer v-if="store.businessDetail.business_info">
        <main>
            <profile-header class="hide_this" />
            <nav-bar class="hide_this" />
            <section class="overview-details">
                <div class="container">
                    <div class="row">
                        <!-- Tabs Content -->
                        <div class="col-lg-8 col-12">
                            <overview v-if="!$route.params.tab || $route.params.tab == 'overview'" />
                            <Gallery v-if="!$route.params.tab || $route.params.tab == 'overview' || $route.params.tab == 'gallery'" />
                            <!-- <Reviews :reviews="store.businessDetail.reviews" :reviews_stats="store.businessDetail.reviewsStats" v-if="$route.params.tab == 'reviews'" /> -->
                            <Reviews :reviews="store.businessDetail.reviews" :reviews_stats="store.businessDetail.reviewsStats" v-if="!$route.params.tab || $route.params.tab == 'overview' || $route.params.tab == 'reviews'" class="d-none d-md-block" />
                            <Queue :employees="store.businessDetail.employees" :businessInfo="store.businessDetail.business_info" v-if="$route.params.tab == 'queue' && Object.keys(store.businessDetail.business_info).length > 0"/>
                            <Services :services="store.businessDetail.services" v-if="$route.params.tab == 'services'"/>
                            <Deals :deals="store.businessDetail.deals" v-if="$route.params.tab == 'deals'"/>
                        </div>
                        <!-- Sidebar -->
                        <div class="col-lg-4 col-12">
                            <side-bar :businessInfo="store.businessDetail.business_info" />
                            <Reviews :reviews="store.businessDetail.reviews" :reviews_stats="store.businessDetail.reviewsStats" class="d-md-none d-block" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </MainAppContainer>
</template>


<script setup>
import { watch } from 'vue'
import $ from 'jquery'
import MainAppContainer from '@/components/containers/MainAppContainer'
import ProfileHeader from '@/components/businessProfile/ProfileHeader'
import NavBar from '@/components/businessProfile/NavBar'
import Overview from '@/components/businessProfile/overview'
import SideBar from '@/components/businessProfile/rightSideBar'
import Queue from '@/components/businessProfile/queue.vue'
import Services from '@/components/businessProfile/services.vue'
import Deals from '@/components/businessProfile/deals.vue'
import Gallery from '@/components/businessProfile/gallery.vue'
import Reviews from '@/components/businessProfile/reviews.vue'

import { useBusinessStore } from '@/stores/business'
import { useRoute } from 'vue-router'
import { useStorage } from "vue3-storage";

const route = useRoute()
const store = useBusinessStore()
const storage = useStorage()

let user_id = ''
if (storage.getStorageSync('user') != undefined && storage.getStorageSync('user') != '') {
    user_id = storage.getStorageSync('user').id
}
store.getBusinessDetail(route.params.slug,route.params.tab,user_id)

watch(() => route.params.tab, (tab) => {
    const hasNoData = (data) => data == undefined || data == 'undefined' || data == null || data == '';

    if (tab == 'queue' && hasNoData(store.businessDetail.employees)) {
        store.getBusinessDetail(route.params.slug,tab,user_id)
        if ($(window).width() < 575) {
            $('.hide_this').fadeOut();
        }
    } else if ((tab == 'deals' || tab == 'services') && hasNoData(store.businessDetail[tab])) {
        store.getBusinessDetail(route.params.slug,tab,user_id)
        if ($(window).width() < 575) {
            $('.hide_this').fadeOut();
        }
    } else if (tab == 'reviews' && hasNoData(store.businessDetail.reviews)) {
        store.getBusinessDetail(route.params.slug,tab,user_id)
        $('.hide_this').fadeIn();
    } else if (tab == 'gallery' && hasNoData(store.businessDetail.photos)) {
        store.getBusinessDetail(route.params.slug,tab,user_id)
        $('.hide_this').fadeIn();
    } else if (hasNoData(store.businessDetail.photos) || hasNoData(store.businessDetail.reviews)) {
        store.getBusinessDetail(route.params.slug,tab,user_id)
        $('.hide_this').fadeIn();
    }






    console.log(tab)
    // if (route.params.tab == 'queue') {
    //     if (store.businessDetail.employees == undefined || store.businessDetail.employees == 'undefined' || store.businessDetail.employees == null || store.businessDetail.employees == '') {
    //         this.getBusinessDetail(route.params.tab)
    //         if ($(window).width() < 575) {
    //             $('.hide_this').fadeOut();
    //         }
    //     }
    // }else if (route.params.tab == 'deals') {
    //     if (store.businessDetail.deals == undefined || store.businessDetail.deals == 'undefined' || store.businessDetail.deals == null || store.businessDetail.deals == '') {
    //         this.getBusinessDetail(this.$route.params.tab)
    //         if ($(window).width() < 575) {
    //             $('.hide_this').fadeOut();
    //         }
    //     }
    // }else if (route.params.tab == 'services') {
    //     if (store.businessDetail.services == undefined || store.businessDetail.services == 'undefined' || store.businessDetail.services == null || store.businessDetail.services == '') {
    //         this.getBusinessDetail(this.$route.params.tab)
    //         if ($(window).width() < 575) {
    //             $('.hide_this').fadeOut();
    //         }
    //     }
    // }else if (route.params.tab == 'reviews') {
    //     if (store.businessDetail.reviews == undefined || store.businessDetail.reviews == 'undefined' || store.businessDetail.reviews == null || store.businessDetail.reviews == '') {
    //         this.getBusinessDetail(this.$route.params.tab)
    //         $('.hide_this').fadeIn();
    //     }
    // }else if (route.params.tab == 'gallery') {
    //     if (store.businessDetail.photos == undefined || store.businessDetail.photos == 'undefined' || store.businessDetail.photos == null || store.businessDetail.photos == '') {
    //         this.getBusinessDetail(this.$route.params.tab)
    //         $('.hide_this').fadeIn();
    //     }
    // }else if (route.params.tab == undefined) {
    //     if (store.businessDetail.photos == undefined || store.businessDetail.photos == 'undefined' || store.businessDetail.photos == null || store.businessDetail.photos == '' || 
    //         store.businessDetail.reviews == undefined || store.businessDetail.reviews == 'undefined' || store.businessDetail.reviews == null || store.businessDetail.reviews == '') {
    //         this.getBusinessDetail(this.$route.params.tab)
    //         $('.hide_this').fadeIn();
    //     }
    // }
});
</script>
