<template>
    <div class="reviews-content">
        <div class="reviews-header">
            <div class="row" v-if="reviews_stats">
                <div class="col-12" v-on:click.prevent="$router.push({name:'BusinessProfile',params: {slug:$route.params.slug,tab:'reviews'}})">
                    <h2 class="mb-4 d-flex" >
                        <span class="pt-2 me-3">{{reviews_stats.rating}}</span>
                        <star-rating :rating=" roundToHalf(reviews_stats.rating)" :star-size="30" :round-start-rating="false" :show-rating="false" :read-only="true"></star-rating>
                        <span class="pt-sm-3 pt-2 ms-2 fs-5 fw-bold">({{reviews_stats.total_reviews}}) <span v-if="reviews_stats.total_reviews > 1">Reviews</span> <span v-else>Review</span></span>
                    </h2>
                </div>
                <div class="col-12 col-md-6">
                    <div class="progress-bar-wrapper">
                        <div class="fc">5</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_five,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_five" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-wrapper">
                        <div class="fc">4</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_four,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_four" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-wrapper">
                        <div class="fc">3</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_three,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_three" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-wrapper">
                        <div class="fc">2</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_two,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_two" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-wrapper">
                        <div class="fc">1</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_one,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_one" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12" v-if="reviews_stats">
                    <div class="stars-wrapper">
                        <span class="me-3 fc">Quality of Service</span>
                        <div class="sc"><star-rating :rating="roundToHalf(reviews_stats.avg_quality)" :title="reviews_stats.avg_quality" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div>
                    <div class="stars-wrapper">
                        <span class="me-3 fc">Customer Service</span>
                        <div class="sc"><star-rating :rating="roundToHalf(reviews_stats.avg_skill)" :title="reviews_stats.avg_skill" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div>
                    <div class="stars-wrapper">
                        <span class="me-3 fc">Cleanliness and Hygiene</span>
                        <div class="sc"><star-rating :rating="roundToHalf(reviews_stats.avg_communication)" :title="reviews_stats.avg_communication" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div>
                    <div class="stars-wrapper">
                        <span class="me-3 fc">Time Management</span>
                        <div class="sc"><star-rating :rating="roundToHalf(reviews_stats.avg_timing)" :title="reviews_stats.avg_timing" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div>
                    <!-- <div class="stars-wrapper">
                        <span class="me-3 fc">Would recommend</span>
                        <div class="sc"><star-rating :rating="roundToHalf(reviews_stats.avg_recommendation)" :title="reviews_stats.avg_recommendation" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="reviews-inner-body" v-if="reviews">
            <div class="review-box" v-for="review in reviews" :key="review.id">

                <div class="author">
                    <span class="author-image"><img src="@/assets/images/icons/avatr.png" /></span>
                    <span class="author-name">{{review.user.name}}</span>
                    <span class="post-date"> {{formattedDate(review.created_at)}}</span>
                </div>

                <div class="review-rating">
                    <star-rating :rating="roundToHalf(review.overall_rating)" :title="review.overall_rating" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating>
                    <p class="mb-2">Service: {{review.services}} </p>
                </div>
                <div class="review-description">
                    <p v-if="review.business_review.length > 155"><span>{{review.business_review.substring(0, 155)}}</span><span v-if="readMore">{{review.business_review.substring(155, 4000)}}</span> <a href="#!" @click="readMore =! readMore"><span v-if="readMore">See Less</span><span v-else>See More</span></a></p>
                    <p v-else>{{review.business_review}}</p>
                    <div class="review-help-btn pt-2">
                        <p class="mb-0 text-muted">
                            <span :id="'helpfulCounter_' + review.id">{{ review.helpful }}</span>
                            people found this helpful
                        </p>

                        <button v-if="!$storage.getStorageSync('token')"  class="btn help-button shadow-sm"  data-bs-toggle="modal" data-bs-target="#loginModal" >Helpful</button>

                        <button v-else type="button" :id="'helpful_' + review.id" class="btn help-button shadow-sm"  v-on:click="helpFulReview(review.id,review.business_id)" :disabled="review.isHelpful" >Helpful</button>

                        <button v-if="!$storage.getStorageSync('token')" class="btn report-button"  data-bs-toggle="modal" data-bs-target="#loginModal">Report</button>
                        <button v-else type="button" v-on:click="openModal(review.id,review.business_id,review.business_review)"  data-bs-toggle="modal" data-bs-target="#reportModal"  class="btn report-button" >Report</button>

                    </div>
                </div>
            </div>
            <!-- Reviews Box -->
        </div>
        <!-- Reviews Inner Body -->

        <!-- Repoer Modal -->
        <div class="modal fade" id="reportModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Report Review</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Professional </label>
                                    <select v-model="reportReviews.subject" class="form-control">
                                        <option value="False Statment" :selected="selected">False statment</option>
                                        <option value="Abusive Language">Abusive language</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Description</label>
                                    <textarea  v-model="reportReviews.description" class="form-control h-auto"  rows="7"  placeholder="Hi ABC business! The given review is false"></textarea>
                                </div>
                            </div>
                        </div>
                    
                    
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="closeReportModal" class="ond-btn-w" data-bs-dismiss="modal">Cancel</button>
                        <a href="#" class="ond-btn-p" v-on:click.prevent="reportReview">Report</a>
                    </div>
                </div>
            </div>
        </div>
         <!-- Repoer Modal -->
    </div>
</template>

<script>
    import $ from 'jquery'
    import StarRating from 'vue-star-rating'
    import axios from "axios"
    // import useVuelidate from '@vuelidate/core'
    // import { required, integer } from '@vuelidate/validators'
    import { useToast } from "vue-toastification"

    const toast = useToast();

    export default {
        props: {reviews:Array,reviews_stats:Object},
        components: {
            StarRating
        },
        data() {
            return {
                readMore: false,
                isModalVisible: false,
                reportReviews : {
                    subject: "",
                    description:"",
                    reportedReview:"",
                    reviewId : "",
                    businessId : ""

                }
            }
        },
        methods:{
            getPercentage(obtain,total){
                return (obtain/total)*100;
            },
            helpFulReview(review_id,business_id){
               let thiss = this
                axios.post('review_helpful',{'review_id':review_id,'business_id':business_id}).then(function (response) {
                    if (response.data.status == 'success') {
                      console.log(response.data.data);
                    $('#helpful_' + review_id).prop('disabled', true);
                    $('#helpfulCounter_' + review_id).html(response.data.data);

                    }else if (response.data.status == 'login') {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
            },
            reportReview(){
                
                let thiss = this
                if(thiss.reportReviews.reviewId == "" ||  thiss.reportReviews.businessId == "" || thiss.reportReviews.subject == "")
                {
                    toast.error("Somthing is missing! Please try again");
                    return;
                }

                axios.post('review_report',thiss.reportReviews).then(function (response) {
                    if (response.data.status == 'success') {
                        toast.success("Report submitted successfully");
                        $('#closeReportModal').trigger('click');
                        thiss.reportReviews.reviewId = "";
                        thiss.reportReviews.businessId = ""; 
                        thiss.reportReviews.subject = "";
                        thiss.reportReviews.description = "";
                       
                    }else if (response.data.status == 'login') {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
            },
            roundToHalf(number) {
                const decimalPart = number - Math.floor(number);

                // Check if the decimal part is greater than 0.5
                if (decimalPart > 0.5) {
                    return Math.floor(number * 2) / 2;
                } else if (decimalPart < 0.5) {
                    return Math.ceil(number * 2) / 2;
                }else {
                    return number;
                } 
            },
            openModal(reviewId, businessId,reportedReview) {
                this.reportReviews.reviewId = reviewId;
                this.reportReviews.businessId = businessId;
                this.reportReviews.reportedReview = reportedReview;

            },
            formattedDate(review_date) {
                const date = new Date(review_date);
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }
        }
    }
</script>

<style>
    .progress{
        height:10px
    }
    .progress-bar-wrapper,.stars-wrapper{
        width: 100%;
        display: flex;
        margin-bottom: 5px;
    }
    .progress-bar-wrapper .fc{
        width: 30px;
        float: left;
    }
    .progress-bar-wrapper .sc{
        width: calc(85% - 30px);
        float: left;
        padding-top: 6px;
    }
    .stars-wrapper .fc{
        width:60%;
    }
    .stars-wrapper .sc{
        width:40%;
        margin-top: -2px;
    }
</style>