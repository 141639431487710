<template>
    <div class="detail-box">
        <h4><span><img src="@/assets/images/icons/covid-19.png" /> Safety Precautions</span></h4>
        <ul class="ps-0">
            <li><p class="mb-2"><fa icon="check-circle" /> Get tested for Covid-19 if needed</p></li>
            <li><p class="mb-2"><fa icon="check-circle" /> Staying up to date with Covid-19 vaccines</p></li>
            <li><p class="mb-2"><fa icon="check-circle" /> Stay home if you have suspected or confirmed Covid-19</p></li>

            <!-- <li><p class="mb-2"><fa icon="check-circle" /> Our professionals wear mask throughout the service.</p></li>
            <li><p class="mb-2"><fa icon="check-circle" /> We require every client wear their mask.</p></li>
            <li><p class="mb-2"><fa icon="check-circle" /> Our professionals are vaccinated.</p></li>
            <li><p class="mb-2"><fa icon="check-circle" /> We provide hand sanitizer.</p></li> -->
        </ul>
    </div>
</template>